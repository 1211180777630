import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import useDefinedMessagesToHeaderLabels from '@/hooks/useDefinedMessagesToHeaderLabels'

import {columnTitles} from './messages'
import {defaultColumnOrder} from './types'

const InvoiceList = lazy(() => import('./InvoiceList'))

export const LazyInvoiceList = () => {
    const titles = useDefinedMessagesToHeaderLabels(columnTitles)

    return (
        <TablePageSuspense
            buttons={[{label: <FormattedMessage defaultMessage="Export" />}]}
            defaultColumnOrder={defaultColumnOrder}
            headerLabels={titles}
            title={{label: <FormattedMessage defaultMessage="Invoices" />}}>
            <InvoiceList />
        </TablePageSuspense>
    )
}

export default LazyInvoiceList
