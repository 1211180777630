import cn from 'classnames'

import {SVGProps} from './props'

export const ArrowUp = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-arrow-up', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.597 10.403L9.7 6.506V16H6.3V6.506l-3.897 3.897L0 8l8-8 8 8z" fillRule="evenodd" />
    </svg>
)

export default ArrowUp
