import {forwardRef} from 'react'
import MUITableBody, {TableBodyProps as MUITableBodyProps} from '@mui/material/TableBody'
import cn from 'classnames'

import Typography from '@waybridge/wui/Typography/Typography'
import TableCell from '@waybridge/wui/WTable/TableCell'
import TableRow from '@waybridge/wui/WTable/TableRow'

export type TableBodyProps = MUITableBodyProps & {withMonthLabel?: string; height?: number}

/**
 * ...
 */
export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
    ({className, withMonthLabel = undefined, height, children, ...props}, ref) => (
        <MUITableBody
            className={cn('TableBody', className)}
            ref={ref}
            {...props}
            {...(withMonthLabel && {
                sx: (theme) => ({
                    transform: 'translate(0)',
                    tr: {
                        '&:first-of-type': {
                            left: '-10px',
                            height: `100%`,
                            display: 'block',
                            position: 'absolute',
                            width: '3px',
                            backgroundColor: theme.palette.secondary.main,
                            ...(height && {maxHeight: `calc(${height}px - ${theme.spacing(2)})`}),
                        },
                    },
                    '&::after': {
                        content: '""',
                        display: 'block',
                        height: theme.spacing(2),
                    },
                }),
            })}>
            {withMonthLabel ? (
                <TableRow>
                    <TableCell
                        colSpan={12}
                        style={{
                            top: '20px',
                            padding: '0',
                            border: 'unset',
                            display: 'block',
                            position: 'sticky',
                            overflow: 'visible',
                            background: 'transparent',
                        }}>
                        <Typography
                            component="div"
                            fontWeight="bold"
                            sx={{
                                width: '40px',
                                height: '40px',
                                transformOrigin: 'left top',
                                transform: 'rotate(-90deg) translate(-100%, -50%)',
                            }}
                            variant="body2">
                            {withMonthLabel}
                        </Typography>
                    </TableCell>
                </TableRow>
            ) : null}
            {children}
        </MUITableBody>
    ),
)

TableBody.displayName = 'TableBody'

export default TableBody
