import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import {allTradesColumnTitles} from './labels'
import {defaultColumnOrder} from './types'

const InventoryManager = lazy(() => import('@/pages/InventoryManager'))

export const LazyInventoryManager = ({showClosedAccounts}: {showClosedAccounts?: boolean}) => (
    <TablePageSuspense
        buttons={[
            {label: <FormattedMessage defaultMessage="Export" />},
            ...(showClosedAccounts ? [] : [{label: <FormattedMessage defaultMessage="Add account" />}]),
        ]}
        defaultColumnOrder={defaultColumnOrder}
        headerLabels={allTradesColumnTitles}
        title={{label: 'Trade Manager'}}
        weightUnits={[WeightQuantityUnit.Lb, WeightQuantityUnit.Mt]}>
        <InventoryManager showClosedAccounts={showClosedAccounts} />
    </TablePageSuspense>
)

export default LazyInventoryManager
