import {CSSProperties, RefObject, useMemo} from 'react'

import {styled, SxProps, Theme} from '@waybridge/wui'
import WUITableHead, {TableHeadProps} from '@waybridge/wui/TableHead'
import HeaderShadow from '@waybridge/wui/WTable/HeaderShadow'
import TableHeader from '@waybridge/wui/WTable/TableHeader'
import TableRow from '@waybridge/wui/WTable/TableRow'

import useFlags from '@/hooks/useFlags'
import {REZISE_LINE_WIDTH} from '@/hooks/useTableSettings'

import {TableStateProvider} from './Table'
import {TableStateColumn} from './useTableState'
import {useTableContext} from './utils'

export type {TableHeadProps}

const ResizeLine = styled('div')(({theme}) => ({
    zIndex: 1,
    top: '0px',
    height: '100%',
    position: 'absolute',
    cursor: 'col-resize',
    width: `${REZISE_LINE_WIDTH}px`,
    left: `calc(100% - ${REZISE_LINE_WIDTH / 2}px)`,

    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    },

    '&:active': {
        backgroundColor: theme.palette.secondary.main,
    },
}))

const TableHead = ({
    columns,
    columnRefs,
    sx = {},
    ...props
}: TableHeadProps & {
    columns: TableStateColumn[]
    columnRefs?: Record<string, RefObject<HTMLDivElement>>
    sx?: SxProps<Theme>
}) => {
    const ctx = useTableContext()
    const {newTradeManager} = useFlags()
    const tableCtx = useMemo(() => ({...ctx, isHeader: true, isSelectable: ctx.isSelectable ?? false}), [ctx])

    return (
        <TableStateProvider {...tableCtx}>
            <WUITableHead {...props} sx={{position: 'sticky', top: '0px', zIndex: '3', ...sx}}>
                <TableRow>
                    {(columns || []).map(({field, label, Header, isLive, size, minSize, isResizable, ...props}) =>
                        Header ? (
                            <Header key={field} />
                        ) : (
                            <TableHeader
                                key={field}
                                size="small"
                                {...props}
                                {...(newTradeManager && columnRefs?.[field] && isResizable
                                    ? {
                                          style: {
                                              ...((props?.sx as CSSProperties)?.position !== 'sticky' && {
                                                  position: 'relative',
                                              }),
                                              ...(size
                                                  ? {
                                                        width: `${size}px`,
                                                        minWidth: `${size}px`,
                                                        maxWidth: `${size}px`,
                                                    }
                                                  : {
                                                        minWidth: `${minSize}px`,
                                                    }),
                                          },
                                      }
                                    : {})}>
                                {label}
                                {newTradeManager && columnRefs?.[field] && isResizable ? (
                                    <ResizeLine ref={columnRefs[field]} />
                                ) : null}
                            </TableHeader>
                        ),
                    )}
                </TableRow>
                <HeaderShadow zIndex="3" />
            </WUITableHead>
        </TableStateProvider>
    )
}

export default TableHead
