import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import {inventorySearchHeaderLabels} from './headerLabels'
import {defaultColumnOrder} from './types'

const InventorySearch = lazy(() => import('./InventorySearch'))

export const LazyInventorySearch = () => (
    <TablePageSuspense
        buttons={[{label: <FormattedMessage defaultMessage="Export" />}]}
        defaultColumnOrder={defaultColumnOrder}
        headerLabels={inventorySearchHeaderLabels}
        search={{width: 500}}
        title={{label: <FormattedMessage defaultMessage="Search Inventory" />}}
        weightUnits={[WeightQuantityUnit.Lb, WeightQuantityUnit.Mt]}>
        <InventorySearch />
    </TablePageSuspense>
)

export default LazyInventorySearch
