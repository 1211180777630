import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiInputLabel = {
    styleOverrides: {
        root: ({theme}) => ({
            fontSize: '0.875rem',
            '&.MuiFormLabel-colorPrimary.Mui-focused': {
                color: theme.palette.grey70,
                '&.Mui-error': {
                    color: theme.palette.error.main,
                },
            },
            '&.Mui-disabled': {
                '&.MuiInputLabel-outlined': {
                    color: theme.palette.grey60,
                },
            },
            '&.MuiInputLabel-outlined': {
                transform: 'translate(10px, 10px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    fontSize: '10px',
                    transform: 'translate(14px,-6px)',
                },
                // also see MuiOutlinedInput for the 'fieldset legend'.
            },
            '&.MuiInputLabel-standard': {
                '&.MuiInputLabel-shrink': {
                    fontSize: '10px',
                    transform: 'scale(1)',
                },
            },
            '&.MuiInputLabel-filled': {
                transform: 'translate(10px, 21px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    fontSize: '10px',
                    transform: 'translate(14px,6px)',
                },
            },
            '&.Mui-error': {
                color: theme.palette.error.main,
            },
        }),
        sizeSmall: {
            fontSize: '0.75rem',
        },
        asterisk: ({theme}) => ({
            color: theme.palette.error.main,
        }),
    } as ComponentsOverrides<Theme>['MuiInputLabel'],
}

export default MuiInputLabel
