import {useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'

import {styled} from '@waybridge/wui'
import Box from '@waybridge/wui/Box'
import Drawer from '@waybridge/wui/Drawer'
import IconButton from '@waybridge/wui/IconButton'
import {MenuIcon} from '@waybridge/wui/Icons'
import List from '@waybridge/wui/List'
import ListItemButton from '@waybridge/wui/ListItemButton'
import ListItemText from '@waybridge/wui/ListItemText'
import Stack from '@waybridge/wui/Stack'

import msgs from '@/components/Nav/messages'
import {getLinks} from '@/components/Nav/utils'
import useFlags from '@/hooks/useFlags'
import {usePermissions} from '@/providers/PermissionAndNavProvider'

import SubNavList from './SubNavList'

const ListItem = styled(ListItemButton)(() => ({
    '&.Mui-selected .ListItemText span': {
        fontWeight: 'bold',
    },
    '&:hover': {
        backgroundColor: 'rgba(256, 256, 256, 0.3)',
    },
}))

const MobileDrawer = () => {
    const intl = useIntl()
    const perms = usePermissions()
    const flags = useFlags()
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)

    const links = getLinks(intl, perms, flags)
    const {pathname} = location

    const activeLink = useMemo(() => links.find((link) => pathname.startsWith(link.to)), [links, pathname])

    const ref = useRef<null | HTMLAnchorElement>(null)

    return (
        <>
            <IconButton
                aria-label={intl.formatMessage(msgs.OpenMenu)}
                onClick={() => setIsOpen(!isOpen)}
                variant="white">
                <MenuIcon />
            </IconButton>
            <Drawer
                PaperProps={{
                    sx: (theme) => ({
                        bgcolor: theme.palette.black,
                        color: theme.palette.secondary.contrastText,
                    }),
                }}
                onClose={handleClose}
                open={isOpen}>
                <Stack
                    direction="column"
                    gap={2}
                    onClick={handleClose}
                    onKeyDown={handleClose}
                    role="presentation"
                    style={{width: 250}}>
                    <List>
                        {links.map((link, idx) => (
                            <ListItem
                                activeClassName="Mui-selected"
                                component={NavLink}
                                data-testid={`menu-${idx}`}
                                key={link.to}
                                to={link.to}>
                                <ListItemText primary={link.label} />
                            </ListItem>
                        ))}
                    </List>

                    {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                    {activeLink?.subItems && (
                        <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.grey40}`, pt: 2}}>
                            <SubNavList items={activeLink?.subItems ?? []} navRef={ref} />
                        </Box>
                    )}
                </Stack>
            </Drawer>
        </>
    )
}

export default MobileDrawer
