import React, {useRef, useState} from 'react'
import classNames from 'classnames'
import {MRT_Column, MRT_ColumnDef, MRT_Header, MRT_SortingState, MRT_TableInstance} from 'material-react-table'
import {useIntl} from 'react-intl'

import {styled} from '@waybridge/wui'
import {IconButton} from '@waybridge/wui/IconButton'
import {FilterIcon, FilterListIcon} from '@waybridge/wui/Icons'
import {Popover} from '@waybridge/wui/Popover'
import Stack from '@waybridge/wui/Stack'
import theme from '@waybridge/wui/ThemeProvider/minehub-theme/theme'
import Filter from '@waybridge/wui/WTable/Filter'

export const TinyIconButton = styled(IconButton)({
    '& .MuiSvgIcon-root': {
        fontSize: theme.typography['medium-icons'].fontSize,
    },
})

export type HeaderProps<TData extends Record<string, any>> = {
    children?: React.ReactNode
    className?: string
    filterFn?: any
    Filter?: MRT_ColumnDef<TData>['Filter']
    column: MRT_Column<TData>
    table: MRT_TableInstance<TData>
    header: MRT_Header<TData>
    onSortingChange?: (state: MRT_SortingState) => void
}

export const Header = <TData extends Record<string, any>>({
    children,
    filterFn,
    column,
    className,
    onSortingChange,
    Filter: columnFilter,
    table,
    header,
}: HeaderProps<TData>) => {
    const intl = useIntl()
    const anchorEl = useRef<HTMLDivElement>(null)
    const [showFilter, setShowFilter] = useState(false)
    const canSort = column.getCanSort()
    const canFilter = Boolean(filterFn || columnFilter)
    if (canFilter || canSort) {
        const sorted = column.getIsSorted()
        const isFiltered = column.getIsFiltered()

        const FilterContent = columnFilter
            ? () => {
                  const result = columnFilter({column, header, table})
                  // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
                  return React.isValidElement(result) ? result : <>{result}</>
              }
            : null

        return (
            <Stack
                alignItems="center"
                className={className}
                display="flex"
                flexDirection="row"
                gap={1}
                height={14}
                ref={anchorEl}>
                {children}

                <div>
                    {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                    {canSort && (
                        <TinyIconButton
                            aria-label={
                                sorted === 'desc'
                                    ? intl.formatMessage({defaultMessage: 'Sort descending'})
                                    : intl.formatMessage({defaultMessage: 'Sort ascending'})
                            }
                            aria-pressed={Boolean(sorted)}
                            className={classNames('WuiMTableHeader-SortButton', {
                                'Mui-active': Boolean(sorted),
                                'WuiTableHeader-SortButton-asc': sorted !== 'desc',
                            })}
                            onClick={(e) => {
                                e.stopPropagation()
                                const nextOrder = column.getNextSortingOrder()
                                const sorting: MRT_SortingState = nextOrder
                                    ? [
                                          {
                                              id: column.id,
                                              desc: nextOrder === 'desc',
                                          },
                                      ]
                                    : []
                                if (onSortingChange) {
                                    // manual sorting
                                    onSortingChange(sorting)
                                } else {
                                    table.setState((prev) => ({...prev, sorting}))
                                }
                            }}
                            size="small"
                            sx={{
                                '&.WuiTableHeader-SortButton-asc': {
                                    transform: 'scaleY(-1)',
                                },
                            }}>
                            <FilterListIcon fontSize="small" />
                        </TinyIconButton>
                    )}

                    {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                    {canFilter && (
                        <>
                            <TinyIconButton
                                aria-label={intl.formatMessage({defaultMessage: 'Filter table'})}
                                aria-pressed={isFiltered}
                                className={classNames('WuiMTableHeader-FilterButton', {
                                    'Mui-active': isFiltered,
                                })}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowFilter(true)
                                }}
                                size="small">
                                <FilterIcon fontSize="small" />
                            </TinyIconButton>
                            {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                            {showFilter && FilterContent && (
                                <Popover
                                    anchorEl={anchorEl.current?.closest('.MuiTableCell-root')}
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                    onClose={() => {
                                        setShowFilter(false)
                                    }}
                                    open>
                                    <Filter
                                        onFilter={(data, clear) => {
                                            column.setFilterValue(
                                                clear ?? !Object.values(data).some(Boolean) ? undefined : data,
                                            )
                                            setShowFilter(false)
                                        }}
                                        values={column.getFilterValue() ?? undefined}>
                                        <FilterContent />
                                    </Filter>
                                </Popover>
                            )}
                        </>
                    )}
                </div>
            </Stack>
        )
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    return <>{children}</>
}
