import {ColumnConstraints, ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'

import {InventoryManager$data} from '@/__generated__/InventoryManager.graphql'
import {InventoryManagerFilterQuery$data} from '@/__generated__/InventoryManagerFilterQuery.graphql'
import {DisplayQuantityUnit} from '@/__generated__/types'

export type {InventoryAccountManagerInput} from '@/__generated__/InventoryManagerQuery.graphql'
export {InventoryAccountManagerAccountType} from '@/__generated__/types'

export enum Field {
    accountOwner = 'accountOwner',
    id = 'id',
    accountName = 'accountName',
    material = 'material',
    accountType = 'accountType',
    location = 'location',
    period = 'period',
    orderNumber = 'orderNumber',
    currentBalance = 'currentBalance',
    inTransit = 'inTransit',
    received = 'received',
    released = 'released',
    settings = 'settings',
    readyForRelease = 'readyForRelease',
}

export type RawInventoryAccount = NonNullable<
    NonNullable<NonNullable<InventoryManager$data['inventoryAccountManager']>['edges']>[0]
>['node']
export type InventoryAccount = RawInventoryAccount & {
    skipAccountOwner?: boolean
}
export interface Settings extends Partial<Record<Field, any>> {
    unit: DisplayQuantityUnit
    columns: ColumnState[]
}

//filter types
type Summary = NonNullable<NonNullable<InventoryManagerFilterQuery$data['inventoryAccountManager']>['summary']>
export type Material = NonNullable<Summary['materials'][0]>
export type AccountOwner = NonNullable<Summary['accountHolders'][0]>
export type Location = NonNullable<Summary['locations'][0]>

export const defaultColumnOrder: ColumnState[] = [
    {field: Field.settings, enabled: true},
    {field: Field.accountOwner, enabled: true},
    {field: Field.id, enabled: true},
    {field: Field.accountName, enabled: true},
    {field: Field.accountType, enabled: true},
    {field: Field.material, enabled: false},
    {field: Field.location, enabled: true},
    {field: Field.period, enabled: true},
    {field: Field.orderNumber, enabled: true},
    {field: Field.currentBalance, enabled: true},
    {field: Field.inTransit, enabled: false},
    {field: Field.received, enabled: false},
    {field: Field.released, enabled: false},
    {field: Field.readyForRelease, enabled: true},
]

export const columnContraints: Partial<Record<Field, ColumnConstraints>> = {
    [Field.accountOwner]: {required: true, reorderable: false},
}

export const numbericFields: Field[] = [Field.currentBalance, Field.inTransit, Field.received, Field.released]
