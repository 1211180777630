import {MouseEventHandler} from 'react'
import {IconButton, SxProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useIntl} from 'react-intl'

import {CustomizeIcon} from '@waybridge/wui/Icons'
import TableHeader from '@waybridge/wui/WTable/TableHeader'

export type CustomizeColumnButtonProps = {
    sx?: SxProps<Theme>
    isCustomized?: boolean
    onClick?: MouseEventHandler
    disabled?: boolean
}

export const CustomizeColumnButton = ({sx, isCustomized, onClick, disabled}: CustomizeColumnButtonProps) => {
    const intl = useIntl()
    return (
        <TableHeader
            size="small"
            sx={{
                minWidth: '45px',
                width: '45px',
                maxWidth: '45px',
                paddingInline: 0,
                '> div': {
                    width: 'fit-content',
                    marginInline: 'auto',
                },
                '.WuiTableHeader-Title': {
                    marginRight: '0',
                },
                ...sx,
            }}>
            <IconButton
                aria-label={intl.formatMessage({defaultMessage: 'Customize columns'})}
                data-attr="Customize columns"
                data-test-attribute={isCustomized ? 'CustomizedColumns-isCustom' : 'CustomizedColumns'}
                disabled={disabled}
                onClick={onClick}
                size="medium">
                <CustomizeIcon fontSize="medium" sx={{color: isCustomized ? 'secondary.main' : 'grey70'}} />
            </IconButton>
        </TableHeader>
    )
}

export default CustomizeColumnButton
