import cn from 'classnames'

import {SVGProps} from './props'

export const Unlock = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-unlock', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M11.6 7.2H5.2V4C5.2 2.6768 6.2768 1.6 7.6 1.6C8.9232 1.6 10 2.6768 10 4V4.8H11.6V4C11.6 1.7944 9.8056 0 7.6 0C5.3944 0 3.6 1.7944 3.6 4V7.2C2.7176 7.2 2 7.9168 2 8.8V14.4C2 15.2824 2.7176 16 3.6 16H11.6C12.4832 16 13.2 15.2824 13.2 14.4V8.8C13.2 7.9168 12.4832 7.2 11.6 7.2ZM7.6 12.8C6.9376 12.8 6.4 12.2624 6.4 11.6C6.4 10.9376 6.9376 10.4 7.6 10.4C8.2624 10.4 8.8 10.9376 8.8 11.6C8.8 12.2624 8.2624 12.8 7.6 12.8Z"
            fillRule="evenodd"
        />
    </svg>
)

export default Unlock
