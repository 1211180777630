import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLazyLoadQuery} from 'react-relay/hooks'

import Autocomplete from '@waybridge/wui/Autocomplete'
import Button from '@waybridge/wui/Button'
import Dialog from '@waybridge/wui/Dialog'
import DialogActions from '@waybridge/wui/DialogActions'
import DialogContent from '@waybridge/wui/DialogContent'
import DialogTitle from '@waybridge/wui/DialogTitle'
import OrgAvatar from '@waybridge/wui/OrgAvatar'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

import {useAuth} from '@/providers/AuthProvider'

import {SwitchOrgDialogQuery} from '@/__generated__/SwitchOrgDialogQuery.graphql'

import {getOrgs} from './SwitchOrgDialog.queries'
import {Org} from './types'

type SwitchOrgModalProps = {
    onClose: () => void
    orgPk: number
}

export const SwitchOrgModal = ({onClose, orgPk}: SwitchOrgModalProps) => {
    const intl = useIntl()
    const auth = useAuth()
    const [org, setOrg] = useState<Org | undefined>()
    const data = useLazyLoadQuery<SwitchOrgDialogQuery>(getOrgs, {})

    const orgs = (data?.meAdmin?.orgSwitchTargets ?? []).filter((org) => org.pk !== orgPk)

    const handleChange = (_event: React.SyntheticEvent, option: Org | null) => {
        if (option) {
            setOrg(option)
        }
    }

    const handleSubmit = () => {
        if (org?.pk) {
            auth.setEffectiveOrganization(org.pk)
            // refresh the page
            window.location.href = window.location.pathname
        }
    }

    return (
        <Dialog disableRestoreFocus onClose={onClose} open size="sm">
            <form onSubmit={handleSubmit}>
                <DialogTitle onClose={onClose}>
                    <FormattedMessage defaultMessage="Switch Organization" />
                </DialogTitle>
                <DialogContent>
                    <Autocomplete<Org, false, false, false>
                        InputProps={{autoFocus: true}}
                        autoHighlight
                        getOptionLabel={(option) => `${option.name} (${option.pk})`}
                        onChange={handleChange}
                        openOnFocus
                        options={orgs}
                        placeholder={intl.formatMessage({defaultMessage: 'Search for an organization'})}
                        renderOption={(params: Record<string, any>, org: Org) => (
                            <li {...params}>
                                <Stack alignItems="center" direction="row" gap={2}>
                                    <OrgAvatar org={org} />
                                    <Typography>
                                        {org.name} ({org.pk})
                                    </Typography>
                                </Stack>
                            </li>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={!org} type="submit" variant="contained">
                        <FormattedMessage defaultMessage="Switch" />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default SwitchOrgModal
