import cn from 'classnames'

import {SVGProps} from './props'

export const ScheduleOut = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-schedule-out', className)}
        fill={fill}
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.235 14.172L24 18.937l-1.431 1.432-2.322-2.322v5.655h-2.025v-5.655l-2.32 2.322-1.432-1.432 4.765-4.765zM6.075 0v3.037h8.098V0h2.025v3.037h2.024c1.117 0 2.025.91 2.025 2.025v7.167c-.332-.047-.668-.08-1.012-.08-3.914 0-7.087 3.172-7.087 7.086 0 .344.034.68.081 1.012H2.025C.908 20.247 0 19.34 0 18.222V5.062c0-1.116.908-2.025 2.025-2.025h2.024V0h2.025zm12.147 5.062H2.025v3.037h16.197V5.062z"
            fillRule="evenodd"
            transform="translate(-340 -228) translate(104 204) translate(236 24)"
        />
    </svg>
)
