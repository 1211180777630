import {useMemo} from 'react'
import {useIntl} from 'react-intl'

export const useDefinedMessagesToHeaderLabels = (columnTitles: {[index: string]: {defaultMessage: string}}) => {
    const intl = useIntl()
    return useMemo(
        () =>
            Object.keys(columnTitles).reduce(
                (prev, next) =>
                    next in columnTitles
                        ? {
                              ...prev,
                              [next]: intl.formatMessage(columnTitles[next as keyof typeof columnTitles]),
                          }
                        : prev,
                {} as {[index in keyof typeof columnTitles]: string},
            ),
        [intl, columnTitles],
    )
}

export default useDefinedMessagesToHeaderLabels
