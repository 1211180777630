import {forwardRef, useMemo} from 'react'
import {styled, SxProps} from '@mui/material'
import {useIntl} from 'react-intl'

import {Theme} from '@waybridge/wui'
import {DotItemStatus} from '@waybridge/wui/ItemStatus/DotItemStatus'
import {PillItemStatus} from '@waybridge/wui/ItemStatus/PillItemStatus'
import {statusName, statusTooltip} from '@waybridge/wui/ItemStatus/StatusMessages'
import {getBackgroundColorGeneric, getTextColorGeneric} from '@waybridge/wui/StatusPill'
import {
    receivingStatusBackgroundColorMap,
    ReceivingStatusExtension,
    receivingStatusProgressBackgroundColorMap,
    receivingStatusTextColorMap,
} from '@waybridge/wui/StatusPill/utils'
import Tooltip from '@waybridge/wui/Tooltip'

import {
    BalanceInventoryStatus,
    ReceivingStatus,
    TradeInvoiceType,
    TradeStatusNew,
    TransferStatus as TransferStatusType,
} from '@/__generated__/types'

export type ItemStatusProps = {
    status:
        | BalanceInventoryStatus
        | ReceivingStatus
        | ReceivingStatusExtension
        | TradeStatusNew
        | TradeInvoiceType
        | TransferStatusType
    type: 'pill' | 'dot'
    component?: React.ElementType
    sx?: SxProps<Theme>
}

const Status = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & {component?: React.ElementType}>(
    ({component: Component = 'div', ...props}, ref) => <Component ref={ref} {...props} />,
)

Status.displayName = 'Status'

const StatusContainer = styled(Status)(({component}) => ({
    display: component === 'td' ? 'table-cell' : 'inline-block',
}))

export const ItemStatus = ({sx, status, type, component}: ItemStatusProps) => {
    const intl = useIntl()
    const colors = useMemo(
        () =>
            ({
                text: getTextColorGeneric(receivingStatusTextColorMap, status),
                background:
                    type === 'dot'
                        ? getBackgroundColorGeneric(receivingStatusProgressBackgroundColorMap, status)
                        : getBackgroundColorGeneric(receivingStatusBackgroundColorMap, status),
            } as const),
        [status, type],
    )

    const text = useMemo(() => intl.formatMessage(statusName[status]), [status, intl])

    const title = useMemo(() => {
        if (status in statusTooltip) {
            return intl.formatMessage(statusTooltip[status as keyof typeof statusTooltip])
        }

        return undefined
    }, [status, intl])

    if (!text) {
        return <div />
    }

    return (
        <Tooltip title={title}>
            <StatusContainer component={component} sx={sx}>
                {type === 'dot' ? (
                    <DotItemStatus colors={colors} text={text} />
                ) : (
                    <PillItemStatus colors={colors} text={text} />
                )}
            </StatusContainer>
        </Tooltip>
    )
}
